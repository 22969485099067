import React, { useState, useEffect } from 'react'
import { List } from 'semantic-ui-react'
import { Nav, Affix, Col, Row, Placeholder } from 'rsuite'
import { useSync, useLive } from '../../../utils/Storken'
import { slugify } from '../../../services/CustomerActions'
import Products from './Products'
import { getProducts } from '../../../services/AdminActions'
import { Router } from '../../../../server/routes'

const { Paragraph } = Placeholder

const icons = [
  { name:'Paketler', icon:require('../../../static/icons/box.png') },
  { name:'Çiçek Balı', icon:require('../../../static/icons/bee.png') },
  { name:'Yağlar', icon:require('../../../static/icons/butter.png') },
  { name:'Peynirler', icon:require('../../../static/icons/cheese.png') },
  { name:'Pekmezler', icon:require('../../../static/icons/dut.png') },
  { name:'Kuru Gıdalar', icon:require('../../../static/icons/un.png') }
]
const Categories = (props) => {
  const [categories] = useLive('categories')
  const [products, setProducts] = useLive('products')
  const [active, setActive] = useSync('active')
  const [load, setLoad] = useState(true)
  const styles = { width: 100 }

  const setActiveNav = (e) => {
    setLoad(true)
    setTimeout(() => {
      setActive(e)
      Router.pushRoute(`#${slugify(e?.name)}`)
      setLoad(false)
    }, 500)
  }
  useEffect(() => {
    if (products.length > 0) {
      setLoad(false)
    }
  }, [products])
  useEffect(() => {
    const qUrl = props?.url?.asPath.substring(2, props?.url?.asPath?.length)
    const findingCategory = categories.find(({ name }) => slugify(name) === qUrl)
    if (findingCategory) {
      setActive(findingCategory)
    }
  }, [categories, props, setActive])

  const CustomNav = ({ active, onSelect, ...props }) => (
    <Nav {...props} vertical activeKey={active} onSelect={d => onSelect(d)} style={styles}>
      <Nav.Item href='#tumurunler' key='tumurunler' eventKey={{ name:'tumurunler' }}>Tüm Ürünler</Nav.Item>
      {categories?.map(e => <Nav.Item href={`#${slugify(e.name)}`} key={e._id} eventKey={e}>{e.name}</Nav.Item>)}
    </Nav>
  )

  const MobileNav = ({ active, onSelect }) => (
    <div className='mobile_kategoriler'>
      <div className='kategori_listesi'>
        <div>
          <a href='#tumurunler' onClick={() => onSelect({ name:'tumurunler' })} className={active?.name === 'tumurunler' ? 'mobile_aktif_liste' : ''}>
            Tüm Ürünler
          </a>
        </div>
        {
          categories?.map(e => (
            <div key={e._id}>
              <a href={`#${slugify(e.name)}`} className={active?.name === e.name ? 'mobile_aktif_liste' : ''} onClick={() => onSelect(e)}>
                {e.name} <img width={20} src={icons.find(i => i.name === e.name).icon} alt='' />
              </a>
            </div>
          ))
        }
      </div>
    </div>
  )
  return (

    <Row>
      <Col xs={24}>
        <Affix top={40}>
          <MobileNav
            active={active}
            onSelect={(e) => {
              setActiveNav(e), window.scroll({ top: 180, left: 0 })
            }}
          />
        </Affix>
      </Col>
      <Col xs={4}>
        <Affix top={100} className='kateori_dış_iskelet'>
          <CustomNav active={active} onSelect={e => setActiveNav(e)} />
        </Affix>
      </Col>
      <Col xs={24} md={20} lg={20}>
        <div className='kateori_kapsam'>
          <Row>
            {
              load
                ? (
                  <Row className='placeholders'>
                    <Col lg={6} xs={12}><Paragraph rows={3} graph='image' active /></Col>
                    <Col lg={6} xs={12}><Paragraph rows={3} graph='image' active /></Col>
                    <Col lg={6} xs={12}><Paragraph rows={3} graph='image' active /></Col>
                    <Col lg={6} xs={12}><Paragraph rows={3} graph='image' active /></Col>
                    <Col lg={6} xs={12}><Paragraph rows={3} graph='image' active /></Col>
                    <Col lg={6} xs={12}><Paragraph rows={3} graph='image' active /></Col>
                    <Col lg={6} xs={12}><Paragraph rows={3} graph='image' active /></Col>
                    <Col lg={6} xs={12}><Paragraph rows={3} graph='image' active /></Col>
                    <Col lg={6} xs={12}><Paragraph rows={3} graph='image' active /></Col>
                    <Col lg={6} xs={12}><Paragraph rows={3} graph='image' active /></Col>
                    <Col lg={6} xs={12}><Paragraph rows={3} graph='image' active /></Col>
                    <Col lg={6} xs={12}><Paragraph rows={3} graph='image' active /></Col>

                  </Row>
                )
                : products.map(e => (
                  e.active
                    ? (e?.category === active?._id
                      ? (
                        <Col className='kategori_urunler' key={e._id} xs={12} md={8} lg={6}>
                          <Products prod={e} categories={categories} />
                        </Col>
                      )
                      : active?.name === 'tumurunler'
                        ? (
                          <Col className='kategori_urunler' key={e._id} xs={12} md={8} lg={6}>
                            <Products prod={e} categories={categories} />
                          </Col>
                        )
                        : null
                    )
                    : null
                ))
            }
          </Row>
        </div>
      </Col>
    </Row>

  )
}
export default Categories
