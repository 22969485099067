import React, { useEffect, useState } from 'react'
import useWindowSize from '../../../hoc/useWindowDimensions'

const BannerSlider = () => {
  const { width } = useWindowSize()
  const [up, setUp] = useState(false)

  useEffect(() => {
    if (width > 700) setUp(true)
    else setUp(false)
  }, [width])
  return (
    <div
      className='site-intro'
    >
      <img
        src={up
          ? 'https://kgstatic.s3.eu-central-1.amazonaws.com/components/slider-desktop.jpg'
          : 'https://kgstatic.s3.eu-central-1.amazonaws.com/components/slider-mobile.jpg'}
        alt=''
      />
    </div>
  )
}

export default BannerSlider
